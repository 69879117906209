<template>
    <div>
        <mt>
            <el-button class="funBtn" style="float: left; margin-right: 24px" type="text" @click="goBack">返回</el-button>
            <el-form :inline="true" label-width="120" v-model="params" style="padding: 0px 0px">
                <el-form-item label="销售日期">
                    <el-date-picker v-model="params.sellDate" type="daterange" start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="购买客户">
                    <el-input placeholder="请输入客户姓名或电话" v-model="params.customer"></el-input>
                </el-form-item>
                <el-form-item label="商品">
                    <el-select v-model="params.goodsId" placeholder="商品名称或货号"
                                filterable remote
                               :remote-method="_queryGoods">
                        <el-option v-for="s in meta.goods" :value="s.id" :label="s.name+'('+s.bar+')'"></el-option>
                    </el-select>
                </el-form-item>
                <el-button  @click="onQuery" class="funBtn">查询</el-button>
            </el-form>
        </mt>
        <el-card  style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span  class="funBtn">销量统计</span>
            </div>
            <el-table stripe border :data="statistic" class="funBtn">
                <el-table-column type="index" label="NO." width="70px"></el-table-column>
                <el-table-column label="商品" prop="goodsName"></el-table-column>
                <el-table-column label="条码" prop="goodsBar"></el-table-column>
                <el-table-column label="客户" prop="customerName">
                    <template slot-scope="scope">
                        {{scope.row.customerName}} ({{scope.row.customerPhone}})
                    </template>
                </el-table-column>
                <el-table-column label="销售数量" prop="count"></el-table-column>
                <el-table-column label="销售总额" prop="money">
                    <template slot-scope="scope">
                        {{scope.row.money | fen2yuan}}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination
                        @size-change="onQuery"
                        @current-change="onQuery"
                        :current-page.sync="params.page"
                        :page-sizes="[50, 100]"
                        :page-size.sync="params.size"
                        layout="sizes, prev, pager, next,total"
                        :total="params.total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
    import mt from "@/components/MainTop.vue"
    export default {
        name: "Statistic",
        components:{mt},
        props:['form']
        , data() {
            return {
                params: {
                    goodsId: "",
                    sellDate: [],
                    customer: "",
                    page:0,
                    size:50,
                    total:0
                },
                statistic: [],
                meta: {
                    goods: []
                }
            }
        },
        mounted() {
            this.meta.goods=[this.form];
            this.params.goodsId=this.form.id;
            this.onQuery();
        },
        methods: {
            onQuery() {
                let _this = this;
                let param = {
                    goodsId: this.params.goodsId,
                    customer: this.params.customer,
                    page:this.params.page,
                    size:this.params.size,
                }
                if (this.params.sellDate && this.params.sellDate.length > 1) {
                    param.startDateStr = this.params.sellDate[0];
                    param.endDateStr = this.params.sellDate[1];
                }
                this.$http.get("/api/goods/statistic", {params: param})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            _this.statistic = rsp.data.data || [];
                            _this.params.total=rsp.data.count;
                        }
                    })
            },
            _queryGoods(name) {
                this.meta.loading = true
                this.$http.get("/api/goods/find", {params: {name: name,size:100}})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.meta.loading = false,
                            this.meta.goods = rsp.data.data || [];
                        }
                    }).catch(() => {
                    this.meta.loading = false,
                    this.meta.goods = [];
                })
            },
        }
    }
</script>

<style scoped>

</style>